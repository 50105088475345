<template>
  <div style="position:relative;" class="form-section mt-3">
    <h4>Quick View</h4>
    <exit-button></exit-button>
    <div class="mt-3" style="width: 1200px">
      <dg-text-field
          style="width: 600px;display: inline-block"
          class="mt-1"
          :field_span="18"
          :label_span="2"
          field_mark=""
          :translateLabel="false"
          label="Search"
          placeholder="business name, business number, phone"
          @amUpdated="userSearchUpdated"
          :liveUpdate="true"></dg-text-field>
        <div style="margin-left: 10px;display: inline-block;position: relative;top: -10px;" v-if="resultsCount != 0">{{ resultsCount }} Found(s)</div>
    </div>

    <hr>

    <table cellspacing="10">
      <thead>
        <tr>
          <th width="300">
            Business Name
          </th>
          <th width="300">
            Legal Name
          </th>
          <th width="200">
            Business Number
          </th>
          <th width="180">
            Contact
          </th>
          <th>
            Cellular
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(client, key) in clients" :key="key" @click="rowClick(client)">
          <td style="font-size: 14px;">
            {{client.business_name}}
          </td>
          <td style="font-size: 14px;">
            {{client.business_legal_name}}
          </td>
          <td style="font-size: 14px;">
            {{client.business_number__complete}}
          </td>
          <td style="font-size: 14px;">
            {{client.contact_name}}
          </td>
          <td style="font-size: 14px;">
            {{client.full_cellular}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  components: {
    'exit-button': () => import('@/components/exit-button/View')
  },
  methods: {
    rowClick (client) {
      $router.push({name: 'module->Accounting->Client->view', params: {clientId: client.id}})
    },
    goToDashboard () {
      $router.push({name: 'portal-cpanel->dashboard'})
    },
    userSearchUpdated (field) {
      // this.agGrid.gridApi.setQuickFilter(val);
      let val = field.field.field_value
      if (val == this.userSearch) return;
      this.userSearch = val
      clearTimeout(this.userSearchTimeOut)
      this.userSearchTimeOut = setTimeout(() => {
        this.getEntityListData(1) //this.agGrid.gridApi.setQuickFilter(val);
      }, 200)
    },
    getEntityListData (page, onLoaded = false) {
      $store.dispatch('Accounting-Client-api/quickView', this.userSearch).then( r => {
        this.clients = r.rows
        this.resultsCount = this.clients.length
      })
      /*this.getEntityList({page: page, search: this.userSearch}).then(response => {
        this.tableData = response.rows
        this.recordsCounter = response.count
        this.amReady = true
        this.currentPage = page
      })*/
    },
  },
  data() {
    return {
      resultsCount: 0,
      userSearch: '',
      userSearchTimeOut: null,
      clients: []
    }
  }
}
</script>
